import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
  addDataToDecisionReasons,
  addDataToDriverExplanation, addDataToRecommendationReasons,
  setDecisionFilesSummarizeSidebarOpen, setDecisionInputSidebarOpen,
  setDriverFilesSummarizeSidebarOpen,
  setDriverInputSidebarOpen, setRecommendationFilesSummarizeSidebarOpen, setRecommendationInputSidebarOpen,
} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import {findDriverData} from "../accordion_tree/shared/helpers";
import {isBlank, isPresent, qSortArray} from "../../helpers/common";
import {isDSightDecision} from "../../helpers/home_decision_helpers";
import {showAlert} from "../../store/alerts/actions";
import {saveSideBarData} from "../../store/sidebar/common_actions";
import {
  AddToDetailsButton, aiObjectSlug,
  ChatGptRefine,
  ChatGptSummarizePanelHeader,
  handleAddToDetails, shouldCloseSummarizeSidebarCallback
} from "./ChatGptDriverSummarizePanel";
import FilesSummarizeSection, {filesApiSummarizeRequest} from "./sections/FilesSummarizeSection";
import {aiSummarizeFilesRequest, vectorStoreUploadFilesRequest} from "../../store/decision/actions";
import * as moment from "moment/moment";
import {filterAndSortDataSources, getFilteredDataSources} from "./driver_input/SummarizeFilesButton";
import {loadDecisionsFailure} from "../../store/decision/common_actions";

const getSidebarOpenStatus = (type, sidebar) => {
  switch (type) {
    case 'recommendation':
      return sidebar.recommendationFilesSummarizerSidebar;
    case 'decision':
      return sidebar.decisionFilesSummarizerSidebar;
    case 'driver':
      return sidebar.driverFilesSummarizerSidebar;
  }
};

export const getInitFilesContext = (type, decision, driverData) => {
  switch (type) {
    case 'recommendation':
      return decision.recommendation?.chat_gpt_queries?.find(query => isPresent(query['analyzed_files_slugs']))?.context || [];
    case 'decision':
      return decision.chat_gpt_queries?.find(query => isPresent(query['analyzed_files_slugs']))?.context || [];
    case 'driver':
      return driverData.driver?.chat_gpt_queries?.find(query => isPresent(query['analyzed_files_slugs']))?.context || [];
    default:
      return [];
  }
};

const CombinedFilesSummarizePanel = ({ sidebar, openSideBar, driverData, decision, showAlert, saveSideBarData, type,
                                       addDataToDriverExplanation, openDriverInputSidebar, aiSummarizeFilesRequest,
                                       addDataToRecommendationReasons, addDataToDecisionReasons, openDecisionInputSidebar,
                                       openRecommendationInputSidebar, dataSources, vectorStoreUploadFilesRequest}) => {
  if (isBlank(driverData) && type === 'driver') return null;
  if (isBlank(decision)) return null;
  if (isDSightDecision(decision)) return null;

  const [loaded, setLoaded] = useState(false);
  const [loadedVs, setLoadedVs] = useState(false);
  const [error, setError] = useState('');
  const disabled = isPresent(error) || !loaded || !loadedVs;
  const [context, setContext] = useState(getInitFilesContext(type, decision, driverData))
  const [index, setIndex] = useState(0)
  const assistantContent = context?.filter(c => c.role === 'assistant')
  const explanation = assistantContent[index]?.content
  const [disabledAddButton, setDisabledAddButton] = useState(false)
  const [refineMessage, setRefineMessage] = useState('')
  const objectSlug = aiObjectSlug(type, driverData, decision)
  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, driverSlug, gptDriverSlug} = sidebar

  const apiSummarizeCallback = (refineMessage) => filesApiSummarizeRequest(
    aiSummarizeFilesRequest, setLoaded, setError, context, refineMessage, setContext, objectSlug, setRefineMessage, type, vectorStoreUploadFilesRequest, setLoadedVs
  )
  const detailsData = `<p>${explanation}</p>`;
  const isSidebarOpen = getSidebarOpenStatus(type, sidebar);
  const addToDetails = () => handleAddToDetails({setDisabledAddButton, detailsData, type, openRecommendationInputSidebar,
    openDecisionInputSidebar, openDriverInputSidebar, driverData, addDataToRecommendationReasons,
    addDataToDecisionReasons, addDataToDriverExplanation});

  useEffect(() => {
    loadedVs && loaded && isSidebarOpen && openSideBar(false);
  }, [context.length, dataSources.length, dataSources.map(ds => ds.title).join(',')]);

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      setContext(getInitFilesContext(type, decision, driverData) || []);
    }
  }, [driverInputSidebar, recommendationInputSidebar, decisionInputSidebar,
    getInitFilesContext(type, decision, driverData).length, decision.slug]);

  shouldCloseSummarizeSidebarCallback(
    recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, type,
    driverSlug, gptDriverSlug, sidebar, openSideBar, setError, isSidebarOpen
  )

  return (
    <SlidingPanel
      type="left"
      isOpen={isSidebarOpen}
      panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
      panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content`}
      onClosed={() => {
        !driverInputSidebar && saveSideBarData({ driverSlug: null, isOpened: false });
      }}
      size={52}
    >
      <ChatGptSummarizePanelHeader {...{ openSideBar, showAlert, explanation, disabled, setError,
        headerName: "CloverpopAI file summary" }} />
      <FilesSummarizeSection {...{loaded, setLoaded, error, context, setContext, assistantContent, index, setIndex,
        explanation, apiSummarizeCallback, driverData, type, loadedVs, setLoadedVs}} />
      <div className={`${isMobile ? 'mb-5' : 'side-panel bottom h-auto'} p-3`}>
        <ChatGptRefine {...{ setLoaded, gptApiSummarizeCallback: apiSummarizeCallback, refineMessage, setRefineMessage, disabled }} />
        <AddToDetailsButton {...{ explanation, disabled, addToDetails, type, disabledAddButton }} />
      </div>
    </SlidingPanel>
  );
};

const mapStateToProps = ({ sidebar, decision, current_org, tree }, { type }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const driverData = sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : sidebar.drivers
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const sortedDriverDataSources = qSortArray(allowedDataSources.filter(source => driverSourcesSlugs.includes(source.slug)), true, (o) => moment(o.created_at));

  const dataSources = type === 'recommendation'
    ? getFilteredDataSources(allowedDataSources, 'hide_in_recommendation_panel')
    : type === 'decision'
      ? getFilteredDataSources(allowedDataSources, 'hide_in_decision_panel')
      : sortedDriverDataSources;

  return {
    decision, current_org, sidebar,  dataSources,
    driverData: sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : sidebar.drivers
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const setSideBarOpenAction = ownProps.type === 'recommendation'
    ? setRecommendationFilesSummarizeSidebarOpen
    : ownProps.type === 'decision'
      ? setDecisionFilesSummarizeSidebarOpen
      : setDriverFilesSummarizeSidebarOpen;

  return {
    openSideBar: (data) => dispatch(setSideBarOpenAction(data)),
    saveSideBarData: (data = {}) => dispatch(saveSideBarData(data)),
    showAlert: (data) => dispatch(showAlert(data)),
    addDataToDriverExplanation: (data, callback) => dispatch(addDataToDriverExplanation(data, callback)),
    addDataToRecommendationReasons: (data, callback) => dispatch(addDataToRecommendationReasons(data, callback)),
    addDataToDecisionReasons: (data, callback) => dispatch(addDataToDecisionReasons(data, callback)),
    openRecommendationInputSidebar: () => dispatch(setRecommendationInputSidebarOpen(true)),
    openDecisionInputSidebar: () => dispatch(setDecisionInputSidebarOpen(true)),
    openDriverInputSidebar: (slug) => dispatch(setDriverInputSidebarOpen(true, slug)),
    aiSummarizeFilesRequest: (data, callback, setContext) => dispatch(aiSummarizeFilesRequest(data, callback, setContext)),
    vectorStoreUploadFilesRequest: (data, callback) => dispatch(vectorStoreUploadFilesRequest(data, callback, loadDecisionsFailure))
  };
};

const RecommendationFilesSummarizePanelWrapper = (props) => <CombinedFilesSummarizePanel {...props} type="recommendation" />;
const DecisionChatGptSummarizePanelWrapper = (props) => <CombinedFilesSummarizePanel {...props} type="decision" />;
const DriverFilesSummarizePanelWrapper = (props) => <CombinedFilesSummarizePanel {...props} type="driver" />;

export const RecommendationFilesSummarizePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'recommendation' }), mapDispatchToProps
)(RecommendationFilesSummarizePanelWrapper);

export const DecisionFilesSummarizePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'decision' }), mapDispatchToProps
)(DecisionChatGptSummarizePanelWrapper);

export const FilesSummarizePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'driver' }), mapDispatchToProps
)(DriverFilesSummarizePanelWrapper);