import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
  isHomepageSectionLoaded,
  loadDashboardTemplates,
  loadDecisionsSection,
  loadTemplateSets,
  needToLoadHomepageSection
} from "../../store/homepage/actions";
import { isBlank, isPresent } from "../../helpers/common";
import SearchTemplateFilters from "./filters/SearchTemplateFilters";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { isDefaultTemplateFilters } from "../../helpers/filter_helpers";
import { homeSearchState } from "./SearchSection";
import StartFromScratch from "./create_decision_tab/StartFromScratch";
import TemplatesList from "./create_decision_tab/TemplatesList";
import DraftsList from "./create_decision_tab/DraftsList";
import { TilesLoader } from "./create_decision_tab/TilesLoader";
import DecisionSetModal, { openDecisionSetModal, showDecisionSetModal } from "../modals/DecisionSetModal";
import { closeModal, openModal } from "../../store/modals/actions";
import StartDecisionModal, { showStartDecisionModal } from "../modals/StartDecisionModal";
import { isDSightTemplate } from "../../helpers/template_helpers";
import { checkLoadingEffect, checkSearchChangeEffect } from "../../helpers/callbacks_helpers";
import Switcher, { TABS } from "./create_decision_tab/Switcher";
import TemplatesFilters from "./helpers/TemplatesFilters";
import SearchRow, { resetSearchAction } from "./helpers/SearchRow";
import UploadDecisionDocumentModal, {showUploadDecisionModal} from "../modals/UploadDecisionDocumentModal";
import UploadDecisionDocumentButton from "./create_decision_tab/UploadDecisionDocumentButton";

const CreateDecisionTab = ({
                             isDecisionSet = false, sectionHeader = 'Create decisions',
                             home, org_categories, modal, decision_set,
                             closeModal, current_org,
                             loadDashboardTemplates, loadOrgCategories, loadDecisionsSection, loadTemplateSets,
                             ...opts
                          }) => {
  const {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  } = homeSearchState(home)
  const [viewTab, setViewTab] = useState(TABS.scratch)
  const filtersUsed = isPresent(searchQuery) || home.d_sight_only || isPresent(home.category_options) || isPresent(home.template_users);
  const filteredTemplates = home.create_decision.data.filter((t) => !isDecisionSet || !isDSightTemplate(t))
  const filteredTemplateSets = isDecisionSet ? [] : home.template_sets.data
  const showNullResults = filtersUsed && isBlank(filteredTemplates) && isBlank(filteredTemplateSets);
  const startDecisionSet = useCallback((template_set = null) => {
    openDecisionSetModal({ ...opts, template_set })
  }, [])
  const anyTemplates = (
    isHomepageSectionLoaded(home, 'create_decision') &&
    isHomepageSectionLoaded(home, 'template_sets') &&
    (isPresent(home.create_decision.data) || isPresent(home.template_sets.data))
  )

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'create_decision')) loadDashboardTemplates();
  }, [home.create_decision.loaded])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'template_sets')) loadTemplateSets();
  }, [home.template_sets.loaded])
  useEffect(() => {
    if (anyTemplates) setViewTab(TABS.templates)
  }, [anyTemplates])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'template_sets')) loadTemplateSets();
  }, [home.template_sets.loaded])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'draft') && !isDecisionSet) loadDecisionsSection('draft')
  }, [home.draft.loaded, isDecisionSet])

  checkLoadingEffect(org_categories, loadOrgCategories)
  const loadRecords = (data = {}) => {
    loadDashboardTemplates(data)
    loadTemplateSets(data)
  }
  checkSearchChangeEffect(loading, setLoading, searchQuery, loadRecords)


  return <>
    {
      !isDecisionSet && isHomepageSectionLoaded(home, 'draft') && isPresent(home.draft.data) &&
        <div className="row">
          <DraftsList />
        </div>
    }
    <Switcher {...{
      header: sectionHeader,
      viewTab, setViewTab,
      hidden: isDecisionSet || !anyTemplates,
      className: 'row col-xl search-row',
      showUploadButton: current_org.allow_gpt && viewTab === TABS.templates
    }} />
    {
      (isDecisionSet || viewTab === TABS.templates) &&
      <>
        <SearchRow rowStyles={`${isDefaultTemplateFilters(home) ? 'mb-3' : 'mb-2'}`}
                   {...{ searchQuery, setSearchQuery, placeholder: 'Search templates' }}
                   resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)}>
          <SearchTemplateFilters {...{
            loadAction: loadRecords,
            isSetPage: isDecisionSet,
            templateUsersFilter: !isDecisionSet
          }} />
        </SearchRow>
        <TemplatesFilters {...{ home, org_categories, loadDashboardTemplates: loadRecords }} classNames='mb-3' />
      </>
    }
    <UploadDecisionDocumentButton hidden={isDecisionSet || anyTemplates || !current_org.allow_gpt || viewTab !== TABS.scratch}/>
    <div className="row">
      {
        (isDecisionSet || viewTab === TABS.scratch) &&
        <StartFromScratch {...{ ...opts, showNullResults: (showNullResults && isDecisionSet), startDecisionSet, isDecisionSet }} />
      }
      <TilesLoader hidden={isHomepageSectionLoaded(home, 'create_decision') && isHomepageSectionLoaded(home, 'template_sets')} />
      {
        (isDecisionSet || viewTab === TABS.templates) &&
        <TemplatesList {...{
          showNullResults, filteredTemplates, setSearchQuery, setLoading, startDecisionSet,
          isDecisionSet, selectedDecisionSet: decision_set, filtersUsed
        }} backPath={isDecisionSet && { decision_set: decision_set.slug }} />
      }
    </div>
    <div className="modals">
      <DecisionSetModal shown={showDecisionSetModal(modal)} onClose={closeModal} />
      <StartDecisionModal shown={showStartDecisionModal(modal)} onClose={closeModal} isDecisionSet={isDecisionSet} />
      <UploadDecisionDocumentModal shown={showUploadDecisionModal(modal)} onClose={closeModal} />
    </div>
  </>;
}
const mapStateToProps = ({ home, org_categories, modal, decision_set, current_org }) => ({ home, org_categories, modal, decision_set, current_org});
const mapDispatchToProps = (dispatch) => ({
  loadDashboardTemplates: (hash) => { dispatch(loadDashboardTemplates(hash)) },
  loadDecisionsSection: (section) => { dispatch(loadDecisionsSection(section)) },
  loadOrgCategories: () => dispatch(loadOrgCategories()),
  loadTemplateSets: (hash = {}) => dispatch(loadTemplateSets(hash)),
  closeModal: () => { dispatch(closeModal()) },
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateDecisionTab);
