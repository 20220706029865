import React, {Fragment} from "react";
import { connect } from "react-redux";
import DriverActions from './DriverActions';
import ZTree from "./ZTree";
import {isBlank} from "../../helpers/common";
import { Loader } from '../../common/Loader';
import DetailsModal from "../modals/DetailsModal";
import { transferDataAndCloseModal } from "../../store/modals/actions";

const DETAILS_TYPES = ["WizardDriverDetailsModal", "WizardAddDriverDetailsModal"];

const showDetails = (modal) => DETAILS_TYPES.includes(modal.type) && modal.shown;

const modalTitle = ({ type }) =>
  type === "WizardAddDriverDetailsModal" ? 'Add a driver' : 'Edit driver'

const WizardTreeEditor = ({
                            stepRef, modal, transferDataAndCloseModal,
                            drivers, inSidebar=false, isTemplate = false, isHistoricalDecision = false,
                            collaborators = [], setDrivers, current_org,
                            ...opts
                          }) => {
  if(isBlank(drivers)) return <Loader />;

  return <Fragment>
    <div>
      <div className={`tree-container ${inSidebar ? "" :  "mb-3"}`}>
        <div className={`${inSidebar ? current_org.allow_gpt ? 'tree-body edit-mode-gpt-suggestions' : 'tree-body edit-mode two-rows' : ""}`}>
          <ZTree {...{ isTemplate, drivers, collaborators, isHistoricalDecision, stepRef }} {...opts} />
        </div>
      </div>
      <div className={`actions-container mt-1 container-fluid`}>
        <DriverActions {...{ isTemplate, drivers, setDrivers, isHistoricalDecision, stepRef }} {...opts} />
      </div>
      <div className="modals">
        <DetailsModal shown={showDetails(modal)} title={modalTitle(modal)}
                      onClose={transferDataAndCloseModal}
                      {...{ isHistoricalDecision, isTemplate, stepRef}} />
      </div>
    </div>
  </Fragment>
}

const mapStateToProps = ({  current_org, tree, decision, template, modal },  { isTemplate }) => ({
  current_org, modal, tree, decision: isTemplate ? template : decision
});
const wrapper = React.forwardRef((props, ref) => <WizardTreeEditor {...props} stepRef={ref} />)
export default connect(mapStateToProps, { transferDataAndCloseModal }, null, { forwardRef: true })(wrapper);
